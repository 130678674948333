import React from "react"
import { Typography, Card } from "antd"
import { Animated } from "react-animated-css"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/Layout"
import TrustedBy from "../../components/TrustedBy"
import FeaturedWork from "../../components/WEB_FeaturedWork"
import DevelopmentProcess from "../../components/WEB_DevelopmentProcess"
import TechStack from "../../components/WEB_TechStack"
import SideForm from "../../components/SideForm"
import "../../styles/services.scss"
import "../../styles/animate.css"
const { Title } = Typography

const Index = ({ data }) => {
  const backgroundImage = data.bgImage.childImageSharp.fluid.src
  return (
    <Layout className="services-page-container" data={data} 
    pageTitle={"Visiomate - Web Development Services"}
    descrip = {"From design to deployment, we handle every aspect of web development. Partner with us for innovative, scalable, and engaging web solutions."}>
      <div className="top-section"
        style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="logo">
          <Link to="/">
            <Img fluid={data.VMLogoWhite.childImageSharp.fluid} />
          </Link>
        </div>
        <div className="box-wrapper text-and-form-container">
          <div className="information">
            <Animated
              animationIn="fadeInUp"
              animationInDuration={500}
              animationInDelay={300}
              isVisible={true}
            >
              <Title>Web based enterprise level applications</Title>
              <p>
                We provide state-of-the-art web-based solutions to help our
                customers achieve building highly accessible applications.{" "}
                <br />
                <br />
                Our focus is to enable our customers with the latest
                technologies and trends to maximize their productivity,
                collaboration, and availability.
              </p>
              <TrustedBy showHeading={false} />
            </Animated>
          </div>
          <Animated
            animationIn="fadeInDown"
            animationInDuration={800}
            animationInDelay={500}
            isVisible={true}
            className="form"
          >
            <SideForm />
          </Animated>
        </div>
      </div>
      <div className="heading-and-detail">
        <Title>Web Application Development Services</Title>
        <p>
          We develop many kinds of applications whether it's an application to
          support your operations, maintain the customers' relationship, or
          provide the solution to help your employees. We have enabled many
          organizations with a variety of web applications that are built using
          the latest web technologies.
        </p>
      </div>
      <div className="cards-wrapper">
        <Card title="Dynamic Web Applications">
          <p>
            View and Manage organizational data using user freindly interface.
            User management feature is included to protect your infomation from
            unwanted access.
          </p>
        </Card>
        <Card title="Content Management System">
          <p>
            Manage and organize the website content throug an interface. It
            provides the ability to define user or group based features, such
            as, WordPress.
          </p>
        </Card>
        <Card title="Portal Web App">
          <p>
            These applications can include a variety of features such as
            discussion boards, chatrooms, e-mail, spaces accessible through
            registration, and the most recent content, etc.
          </p>
        </Card>
        <Card title="E-Commerce">
          <p>
            This type of application development process required for such
            application is more complex because it must include supply chain
            management and accept electronic payments via credit cards, PayPal,
            etc.
          </p>
        </Card>
      </div>
      <div className="featured-projects">
        <FeaturedWork data={data} />
      </div>
      <DevelopmentProcess />
      <TechStack />
    </Layout>
  )
}

export default Index

export const webDevelopmentQuery = graphql`
  query webDevelopmentQuery {
    FarmManagementSystem: file(
      relativePath: { eq: "FarmManagementSystem/pf.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ISOComplianceManagement: file(
      relativePath: { eq: "ISOComplianceManagement/iso.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    TenderAssetRecordManagement: file(
      relativePath: { eq: "TenderAssetRecordManagement/tender.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ViztosPos: file(relativePath: { eq: "ViztosPos/vistos_image.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo1: file(relativePath: { eq: "Odoo/odoo1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo2: file(relativePath: { eq: "Odoo/odoo2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odoo3: file(relativePath: { eq: "Odoo/odoo3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Custom: file(relativePath: { eq: "Custom/CustDevelopment.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365: file(relativePath: { eq: "D365/D365.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    D365_2: file(relativePath: { eq: "D365/D365_2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Support: file(relativePath: { eq: "safty.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis: file(relativePath: { eq: "analysis.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Analysis2: file(relativePath: { eq: "analysis2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Development: file(relativePath: { eq: "development.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Connect: file(relativePath: { eq: "connect.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Data: file(relativePath: { eq: "data.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImage: file(relativePath: { eq: "Mask_group8.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    EMS: file(relativePath: { eq: "EMS/ems_side.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Viztos: file(relativePath: { eq: "ViztosPos/viztos_side.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Tender: file(relativePath: { eq: "TenderAssetRecordManagement/trn_side.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`