import React from "react"
import Img from "gatsby-image"
import { Typography, Carousel } from "antd"
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons"

const FeaturedWork = ({ data }) => {
  const { Title } = Typography
  const settings = {
    arrows: true,
    autoplay: true,
    dots: false,
    autoplaySpeed: 3000,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <>
      <Title level={2}>Our Featured Works</Title>
      <div className="reviews-wrapper">
        <Carousel
          {...settings}
          nextArrow={<ArrowRightOutlined />}
          prevArrow={<ArrowLeftOutlined />}
        >
          <div className="reviews-grid">
            <div className="reviews-cover">
              <Img fluid={data.Viztos.childImageSharp.fluid} />
            </div>
            <div className="reviews-description">
              <Title level={3}>
                {" "}
                Viztos is a modern POS with an ERP on the back to support all
                the SCM and Finance operations.{" "}
              </Title>
              <p>
                a software that eliminates the common limitations in any other
                POS softwares businesses face. Viztos has custom designed Front
                End for user-friendly experience and backend runs on Odoo with
                most of the limitations mitigated like multiple sessions,
                web-based system, advanced UI design. <br /> <br />
                Viztos is a premium software that covers your need of tracking
                sales, inventory as well as your customer’s data and the
                statistics of your business with cutting edge tech and with
                minimal steps to operate all of it.
              </p>
              <span className="view-case-study">
                <a href="/caseStudies/products/viztos" >&nbsp;</a>
              </span>
            </div>
          </div>

          <div className="reviews-grid">
            <div className="reviews-cover">
              <Img fluid={data.EMS.childImageSharp.fluid} />
            </div>
            <div className="reviews-description">
              <Title level={3}>
                {" "}
                Education Management System built to ease the management of
                programs, sessions, classes, enrollments, etc.
              </Title>
              <p>
                EMS is made by keeping all the basic needs of any educational
                institutes for training, sessions, programs or educational
                classes, so everything is possible under one roof. First of all
                an organization is registered along with its institutions and
                campuses. <br /> <br /> All programs, courses, classes,
                timetable and even room allocation for all of that can be set in
                our LMS. This Software can help an institute achieve its optimum
                level of efficiency in daily operations as well as the ones
                planned throughout the year.
              </p>
              <span className="view-case-study">
                <a href="/caseStudies/products/classManagement" >&nbsp;</a>
              </span>
            </div>
          </div>

          <div className="reviews-grid">
            <div className="reviews-cover">
              <Img fluid={data.Tender.childImageSharp.fluid} />
            </div>
            <div className="reviews-description">
              <Title level={3}>
                Tender and SLA Management System is focussed on recording all
                the information produced during the process of budget
                consumption through tenders.
              </Title>
              <p>
                Tender and SLA management solution covers the whole process of
                tender publishing, incoming requests, approval and then tender
                passing; procurement department uses this module. <br /> <br />
                Another module in the software was designed for Storage
                department for asset buying, listing and all operations related
                to its management in the store. For IT department we developed a
                module for servers’ security, maintenance, management and
                performance and health of servers and network.
              </p>
              <span className="view-case-study">
                <a href="" >&nbsp;</a>
              </span>
            </div>
          </div>
        </Carousel>
      </div>
    </>
  )
}

export default FeaturedWork
