import React from "react"
import { Typography } from "antd"
const { Title } = Typography

const DevelopmentProcess = () => {
  return (
    <div className="vm-development-process">
      <div className="vm-development-process-header">
        <Title>Our Web Development Process</Title>
        <p>
          Despite various detailed web development processes observed in the
          industry, we rather follow a simplistic approach to develop web-based
          solutions. Our process is based on agile values such as customer
          engagement and working software instead of detailed documentation.
        </p>
      </div>
      <div className="vm-development-process-body">
        <div>
          <div className="number-and-text-wrapper">
            <Title>01</Title>
            <Title level={4}>Inception and Elicitation</Title>
          </div>
          <ul>
            <li>Talk to the customer</li>
            <li>Listen to what the customer has to say</li>
            <li>Brainstorm the ideas presented by the custoner</li>
          </ul>
        </div>
        <div>
          <div className="number-and-text-wrapper">
            <Title>02</Title>
            <Title level={4}>Propose Solutions</Title>
          </div>
          <ul>
            <li>
              Use all the information got from the customer to figure out the
              best solution
            </li>
            <li>Present the solutions to the customer</li>
            <li>Have customers buy in on the solution</li>
          </ul>
        </div>
        <div>
          <div className="number-and-text-wrapper">
            <Title>03</Title>
            <Title level={4}>Implementation</Title>
          </div>
          <ul>
            <li>Allocate team</li>
            <li>Create project backlogs</li>
            <li>Plan sprints</li>
            <li>Start working on the highest priority sprint</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default DevelopmentProcess
